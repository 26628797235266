<!--
 * @Author: XSJ 13592841305@163.com
 * @Date: 2023-08-07 15:31:18
 * @LastEditors: XSJ 13592841305@163.com
 * @LastEditTime: 2023-10-31 11:55:31
 * @FilePath: \cz-operation\src\views\tenantManage\modifyAuth.vue
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
-->
<template>
  <div class="modifyAuth">
    <div class="title" v-if="type == 'modify'">修改认证信息</div>
    <div class="title" v-else>认证审核</div>
    <a-form :form="form" :label-col="{ span: 3 }" :wrapper-col="{ span: 10 }">
      <div class="form-title">基本信息</div>
      <a-form-item label="名称">
        <a-input
          :disabled="type == 'auth'"
          :maxLength="50"
          v-decorator="[
            'name',
            {
              initialValue: tenantInfo ? tenantInfo.name || '' : '',
              rules: [{ required: true, message: '请输入' }],
            },
          ]"
        ></a-input>
        <span style="position: absolute; bottom: -11px; right: 10px"
          >{{ getItemValueLength('name') }}/50</span
        >
      </a-form-item>
      <a-form-item label="联系人">
        <a-input
          :disabled="type == 'auth'"
          :maxLength="20"
          v-decorator="[
            'contacts',
            {
              initialValue: tenantInfo.contacts || '',
              rules: [{ required: true, message: '请输入' }],
            },
          ]"
        ></a-input>
        <span style="position: absolute; bottom: -11px; right: 10px"
          >{{ getItemValueLength('contacts') }}/20</span
        >
      </a-form-item>
      <a-form-item label="联系电话">
        <a-input
          :disabled="type == 'auth'"
          v-decorator="[
            'phoneNumber',
            {
              initialValue: tenantInfo.phoneNumber
                ? tenantInfo.phoneNumber || ''
                : '',
              rules: [{ required: true, message: '请输入' }],
            },
          ]"
        ></a-input>
      </a-form-item>
      <a-form-item label="邮箱">
        <a-input
          :disabled="type == 'auth'"
          :maxLength="50"
          v-decorator="[
            'email',
            {
              initialValue: tenantInfo.businessInformationResponse
                ? tenantInfo.businessInformationResponse.email || ''
                : '',
              rules: [{ required: true, message: '请输入' }],
            },
          ]"
        ></a-input>
        <span style="position: absolute; bottom: -11px; right: 10px"
          >{{ getItemValueLength('email') }}/50</span
        >
      </a-form-item>
      <div class="form-title">工商资料</div>
      <a-form-item label="法定代表人">
        <a-input
          :disabled="type == 'auth'"
          :maxLength="20"
          v-decorator="[
            'legalRepresentative',
            {
              initialValue: tenantInfo.businessInformationResponse
                ? tenantInfo.businessInformationResponse.legalRepresentative ||
                  ''
                : '',
              rules: [{ required: true, message: '请输入' }],
            },
          ]"
        ></a-input>
        <span style="position: absolute; bottom: -11px; right: 10px"
          >{{ getItemValueLength('legalRepresentative') }}/20</span
        >
      </a-form-item>
      <a-form-item label="注册资本">
        <a-input
          :disabled="type == 'auth'"
          suffix="万"
          v-decorator="[
            'registeredCapital',
            {
              initialValue: tenantInfo.businessInformationResponse
                ? tenantInfo.businessInformationResponse.registeredCapital || ''
                : '',
              rules: [{ required: true, message: '请输入' }],
            },
          ]"
        ></a-input>
      </a-form-item>
      <a-form-item label="成立日期">
        <a-date-picker
          :disabled="type == 'auth'"
          v-decorator="[
            'establishmentDate',
            {
              initialValue: tenantInfo.businessInformationResponse
                ? tenantInfo.businessInformationResponse.establishmentDate || ''
                : '',
              rules: [{ required: true, message: '请输入' }],
            },
          ]"
          style="width: 100%"
        />
      </a-form-item>
      <a-form-item label="统一社会信用代码">
        <a-input
          :disabled="type == 'auth'"
          :maxLength="20"
          v-decorator="[
            'unifiedSocialCreditCode',
            {
              initialValue: tenantInfo.businessInformationResponse
                ? tenantInfo.businessInformationResponse
                    .unifiedSocialCreditCode || ''
                : '',
              rules: [{ required: true, message: '请输入' }],
            },
          ]"
        ></a-input>
        <span style="position: absolute; bottom: -11px; right: 10px"
          >{{ getItemValueLength('unifiedSocialCreditCode') }}/20</span
        >
      </a-form-item>
      <a-form-item label="人员规模">
        <a-select
          :disabled="type == 'auth'"
          v-decorator="[
            'staffSize',
            {
              initialValue: tenantInfo.businessInformationResponse
                ? tenantInfo.businessInformationResponse.staffSize || ''
                : '',
              rules: [{ required: true, message: '请选择' }],
            },
          ]"
        >
          <a-select-option
            v-for="item in scaleList"
            :key="item"
            :value="item"
            >{{ item }}</a-select-option
          >
        </a-select>
      </a-form-item>
      <a-form-item label="行业">
        <a-select
          :disabled="type == 'auth'"
          v-decorator="[
            'industry',
            {
              initialValue: tenantInfo.businessInformationResponse
                ? tenantInfo.businessInformationResponse.industry || ''
                : '',
              rules: [{ required: true, message: '请选择' }],
            },
          ]"
        >
          <a-select-option
            v-for="item in industryList"
            :key="item.dictCode"
            :value="item.dictCode"
            >{{ item.dictLabel }}</a-select-option
          >
        </a-select>
      </a-form-item>
      <a-form-item label="省市区">
        <a-cascader
          :disabled="type == 'auth'"
          v-decorator="[
            'regionCode',
            {
              initialValue:
                tenantInfo.businessInformationResponse &&
                tenantInfo.businessInformationResponse.regionCode
                  ? tenantInfo.businessInformationResponse.regionCode.split(
                      ','
                    ) || []
                  : [],
              rules: [{ required: true, message: '请选择' }],
            },
          ]"
          :options="regionData"
          @change="onChange"
          placeholder="请选择"
        ></a-cascader>
      </a-form-item>
      <a-form-item label="经营地址">
        <a-textarea
          :disabled="type == 'auth'"
          :maxLength="200"
          :rows="3"
          v-decorator="[
            'businessAddress',
            {
              initialValue: tenantInfo.businessInformationResponse
                ? tenantInfo.businessInformationResponse.businessAddress || ''
                : '',
              rules: [{ required: true, message: '请输入' }],
            },
          ]"
        ></a-textarea>
        <span style="position: absolute; bottom: -11px; right: 10px"
          >{{ getItemValueLength('businessAddress') }}/200</span
        >
      </a-form-item>
      <a-form-item label="经营范围">
        <a-textarea
          :disabled="type == 'auth'"
          :maxLength="200"
          :rows="4"
          v-decorator="[
            'businessScope',
            {
              initialValue: tenantInfo.businessInformationResponse
                ? tenantInfo.businessInformationResponse.businessScope || ''
                : '',
              rules: [{ required: true, message: '请输入' }],
            },
          ]"
        ></a-textarea>
        <span style="position: absolute; bottom: -11px; right: 10px"
          >{{ getItemValueLength('businessScope') }}/200</span
        >
      </a-form-item>
      <div class="info-img">
        <a-form-item label="Logo" :label-col="{ span: 5 }">
          <a-upload
            :disabled="type == 'auth'"
            style="width: 104px; height: 104px"
            list-type="picture-card"
            :customRequest="(file) => uploadFile(file, 'logo')"
            :fileList="[]"
          >
            <div v-if="!imageUrl_logo">
              <a-icon type="plus" />
              <div class="ant-upload-text">上传</div>
            </div>
            <div v-else>
              <img alt="example" style="width: 100%" :src="imageUrl_logo" />
            </div>
          </a-upload>
        </a-form-item>
        <a-form-item label="营业执照" :label-col="{ span: 5 }">
          <a-upload
            :disabled="type == 'auth'"
            v-if="!imageUrl_license"
            style="width: 270px; height: 150px"
            list-type="picture-card"
            :customRequest="(file) => uploadFile(file, 'license')"
          >
            <div v-if="!imageUrl_license">
              <a-icon type="plus" />
              <div class="ant-upload-text">上传</div>
            </div>
          </a-upload>
          <div v-else class="img-box">
            <div style="width: 270px; height: 150px" class="shade">
              <a-icon type="fullscreen" @click="handlePreview" />
              <a-icon type="delete" @click="delImageUrl_license" />
            </div>
            <img :src="imageUrl_license" style="width: 270px; height: 150px" />
          </div>
          <a-modal
            :visible="previewVisible"
            :footer="null"
            @cancel="handleCancel"
          >
            <img alt="example" style="width: 100%" :src="imageUrl_license" />
          </a-modal>
          <!-- <img :src="tenantInfo.license" style="width: 250px; height: 145px" /> -->
        </a-form-item>
      </div>
    </a-form>
    <!-- 审核结果 -->
    <div class="auth-res" v-if="type == 'auth'">
      <div class="auth-title">审核</div>
      <div>
        <a-radio-group
          @change="radioChange"
          v-model="radioKey"
          style="height: 40px; margin-top: 15px"
        >
          <a-radio value="APPROVED">同意</a-radio>
          <a-radio value="REJECTED">驳回</a-radio>
        </a-radio-group>
        <a-textarea
          v-model="reason"
          :maxLength="100"
          :rows="3"
          v-if="radioKey == 'REJECTED'"
        ></a-textarea>
      </div>
    </div>
    <div class="action">
      <a-button style="margin-right: 10px" @click="cancel">取消</a-button>
      <a-button type="primary" @click="submit">提交</a-button>
    </div>
  </div>
</template>

<script>
import { fullFileUpload } from '@/utils/newFileUpload.js'
import { regionData, CodeToText, TextToCode } from 'element-china-area-data'

export default {
  name: 'ModifyAuth',
  data() {
    return {
      form: this.$form.createForm(this),
      imageUrl_logo: '',
      imageUrl_license: '',
      tenantInfo: {},
      previewVisible: false,
      fileList_license: [],
      scaleList: [
        '1~9人',
        '10~30人',
        '30~50人',
        '50~99人',
        '100~299人',
        '300~499人',
        '500~999人',
        '1000人以上',
      ] /* 规模 */,
      industryList: [] /* 行业 */,
      regionData,
      type: '' /* 修改或审核 */,
      radioKey: 'APPROVED',
      reason: '驳回，理由是：' /* 驳回理由 */,
    }
  },
  created() {
    this.init()
    console.log(regionData)
    this.$nextTick(() => {
      this.$forceUpdate()
    })
  },

  computed: {
    formatAddressCode() {
      return function (codeArrayStr) {
        let codeArray = codeArrayStr
          .split(',')
          .map((item, index) => Number(item.slice(0, (index + 1) * 2)))
        console.log(codeArray)
        return codeArray
      }
    },
  },

  methods: {
    init() {
      this.type = this.$route.query.type
      this.getTenantInfo()
      this.getIndustryList()
    },

    /* 获取输入框值长度 */
    getItemValueLength(val) {
      const value = this.form.getFieldsValue([val])[val]
      return value ? value.length : '0'
    },
    /* 获取租户信息 */
    getTenantInfo() {
      return new Promise((resolve, reject) => {
        this.$api
          ._getTenantBusinessInformation(this.$route.query.id)
          .then((res) => {
            if (res.code == 0) {
              this.tenantInfo = res.data
              this.imageUrl_logo = res.data.logo
              this.imageUrl_license =
                res.data.businessInformationResponse.businessLicense

              if (this.tenantInfo.businessInformationResponse) {
                this.tenantInfo.businessInformationResponse.regionCode =
                  this.formatAddressCode(
                    this.tenantInfo.businessInformationResponse.regionCode
                  ).join(',')
              }

              resolve()
            } else {
              reject()
            }
          })
      })
    },
    /* 获取行业列表 */
    getIndustryList() {
      return new Promise((resolve, reject) => {
        this.$api.getIndustryList().then((res) => {
          if (res.code === 0) {
            this.industryList = res.data
            resolve()
          } else {
            reject()
          }
        })
      })
    },
    /* 上传文件 */
    async uploadFile(file, field) {
      console.log(file)
      try {
        let res = await fullFileUpload(file.file)
        if (field == 'logo') {
          this.imageUrl_logo = res.fileUrl
        }
        if (field == 'license') {
          this.imageUrl_license = res.fileUrl
          this.fileList_license = [
            { url: res.fileUrl, name: res.fileName, uid: -1, status: 'done' },
          ]
        }
        file.onSuccess()
      } catch (err) {
        this.fileList_license = [
          { name: 'image.png', uid: -1, status: 'error' },
        ]
        file.onError()
        console.log(err)
      }
    },
    handleCancel() {
      this.previewVisible = false
    },
    handlePreview() {
      this.previewVisible = true
    },
    handleChange({ fileList }) {
      this.fileList_license = fileList
    },
    delImageUrl_license() {
      this.imageUrl_license = ''
    },
    /* 级联切换 */
    onChange(value) {
      console.log(value)
    },
    /* 取消 */
    cancel() {
      this.$router.push({
        name: 'TenantDetail',
        query: {
          id: this.$route.query.id,
        },
      })
    },
    /* 提交 */
    submit() {
      if (this.type == 'modify') {
        /* 修改认证 */
        this.form.validateFields((err, values) => {
          if (!err) {
            this.$set(values, 'logo', this.imageUrl_logo)
            this.$set(values, 'businessLicense', this.imageUrl_license)
            values.establishmentDate = this.$moment(
              values.establishmentDate
            ).format('YYYY-MM-DD')
            // this.$set(values, 'id', this.tenantInfo.id)
            // this.$set(values, 'isAttestation', this.tenantInfo.isAttestation)
            values.regionCode = values.regionCode.join(',')
            let _data = {
              name: values.name,
              logo: values.logo,
              contacts: values.contacts,
              phoneNumber: values.phoneNumber,
              email: values.email,
              businessInformation: values,
            }
            console.log(_data, 'mmmmmmm')
            this.modifyAuthInfo(_data).then(() => {
              this.cancel()
            })
          }
        })
      } else {
        /* 认证审核 */
        let params = {
          auditStatus: this.radioKey,
        }
        if (this.radioKey == 'REJECTED')
          this.$set(params, 'reason', this.reason)
        this.cancelAuth(params).then(() => {
          this.cancel()
        })
      }
    },
    /* 审核认证 1：同意 0：取消*/
    cancelAuth(params) {
      params = {
        ...params,
        organizationId: this.tenantInfo.id,
      }
      return new Promise((resolve, reject) => {
        this.$api.auditTenant(params).then((res) => {
          if (res.code == 0) {
            resolve()
          } else {
            reject()
          }
        })
      })
    },
    /* 修改认证信息 */
    modifyAuthInfo(data) {
      return new Promise((resolve, reject) => {
        this.$api
          .updateTenantInformation(this.tenantInfo.id, data)
          .then((res) => {
            if (res.code == 0) {
              resolve()
            } else {
              reject()
            }
          })
      })
    },
    /* 审核radio改变 */
    radioChange(e) {
      console.log(e)
      this.radioKey = e.target.value
    },
  },
}
</script>

<style lang="less" scoped>
.modifyAuth {
  padding: 15px;
  // position: relative;
  margin-bottom: 40px;
}
.title {
  height: 80px;
  width: 100%;
  font-size: 20px;
  font-weight: bold;
}

.action {
  height: 60px;
  width: 90%;
  position: absolute;
  bottom: 10px;
  right: 10px;
  background-color: #fff;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  border-top: 1px solid #f5f5f5;
  padding: 0 10px;
}

.form-title {
  margin-left: 10px;
  margin-right: 25px;
  position: relative;
  font-family: '.PingFang SC S0pxibold';
  font-weight: 600;
  font-size: 16px;
  text-align: left;
  color: #151515;
  /* 伪类 */
  &::before {
    content: '';
    position: absolute;
    top: 50%;
    left: -10px;
    transform: translateY(-50%);
    width: 4px;
    height: 20px;
    border-radius: 3px;
    background: #1890ff;
  }
}
.ant-form {
  position: relative;
}

.info-img {
  position: absolute;
  top: 15px;
  right: 15px;
  padding: 10px;
  width: 400px;
}

/deep/ .ant-upload.ant-upload-select-picture-card {
  width: 100%;
  height: 100%;
}

.img-box {
  width: 270px;
  height: 150px;
}

.shade {
  position: absolute;
  z-index: 9;
  display: none;
  width: 100%;
  height: 100%;
}

.img-box:hover .shade {
  display: flex;
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.5);
  font-size: 30px;
  color: #fff;
  i {
    cursor: pointer;
    margin: 10px;
  }
}

/deep/ .ant-input-disabled,
.ant-select-disabled {
  color: rgba(0, 0, 0, 0.65) !important;
  background: #f5f7fb !important;
  cursor: auto;
  // cursor: not-allowed !important;
}

.auth-res {
  border: 1px solid #f5f5f5;
  display: flex;
  flex-direction: column;
  padding: 15px;
  .auth-title {
    font-size: 20px;
    font-weight: bold;
    height: 60px;
    line-height: 60px;
    border-bottom: 1px solid #f5f5f5;
  }
}
</style>
